import styled, { css } from 'styled-components';

interface SelectProps {
  error: boolean;
  hasLabel: boolean;
}

export const Container = styled.div<SelectProps>`
  width: 100%;

  ${({ hasLabel, theme }) =>
    hasLabel &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      .label {
        color: ${theme.title === 'light' ? '#7a7a7a' : '#B5B5C3'};
        font-size: 12px !important;
        font-weight: 600;
      }
    `}

  .sel__control {
    transition: none;
    border-radius: 5px;
    border: 1px solid
      ${props =>
        !props.error
          ? props.theme.title === 'light'
            ? '#7a7a7a'
            : '#2B2B40'
          : '#e12325'};

    box-shadow: ${props => (!props.error ? '' : '0 0 0 0.5px #e12325')};
    background: transparent;

    .sel__value-container--has-value {
      color: ${props =>
        props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
    }

    .sel__value-container {
      .sel__placeholder {
        font-size: 13px;
        color: ${props =>
          props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
      }
      .sel__single-value {
        font-size: 13px;
        color: ${props =>
          props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
      }
    }

    .sel__input {
      font-size: 13px;
      color: ${props =>
        props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
    }
  }

  .sel__indicator-separator {
    display: none;
  }

  .sel__menu {
    background-color: ${props =>
      props.theme.title === 'light' ? '#ffffff' : '#262639'};
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    .sel__menu-list {
      padding: 0;
      .sel__option {
        color: ${props =>
          props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
        background-color: ${props =>
          props.theme.title === 'light' ? '#ffffff' : '#262639'};
        transition: 0.15s ease;
        cursor: pointer;
        &.sel__option--is-focused,
        &.sel__option--is-selected {
          background-color: ${props =>
            props.theme.title === 'light' ? '#ECF8FF' : '#212E48'};
          color: #0095e8;
        }
      }
      &:first-child {
        border-radius: 10px 10px 0 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .sel--is-disabled {
    .sel__control--is-disabled {
      background: #f2f2f2 !important;
      border-color: #e6e6e6 !important;

      .sel__value-container {
        .sel__placeholder {
          color: #808080 !important;
        }
      }
      .sel__single-value {
        color: #b0b0b0 !important;
      }
    }
  }
`;
